import { useGLTF } from '@react-three/drei'

const MainScene = () =>  {
    const model = useGLTF('./models/MainScene/scene_2k.glb')
    return (
        <>
            {/* Overall 3D Scene */}
            <primitive
                castShadow
                receiveShadow
                object={ model.scene }
                scale={ 1 }
                position={ [ 0, 0, 0 ] }
                rotation-y={ 0 }
            />
        </>
    )
}

export default MainScene
