import { useState, useEffect } from 'react'
import { useProgress } from '@react-three/drei'
import classNames from 'classnames'
import '../../styles/modal.scss'
import Share from './Share.js'

function Modal({ currentVisibility, updateVisibility }) {
    const { active } = useProgress()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isModal, setIsModal] = useState(false)

    const beginExperience = () => {
        updateVisibility(false)
        setIsModal(true)
    }

    const modalClasses = classNames({
        'modal': true,
        'is-loaded': isLoaded,
        'is-visible': currentVisibility,
        'is-modal': isModal
    });

    useEffect(() => {
        if(active && !isLoading) {
            setIsLoading(true)
        } else if (!active && isLoading) {
            setIsLoaded(true)
            setIsLoading(false)
        }
    });

    return (
        <div className={modalClasses}>
            <div className="modal__content">
                <div className="modal__title">
                    <img src={'/assets/welcome.svg'} />
                </div>

                <div className="modal__loading">
                    <span />
                </div>

                <p className="modal__text">Because who wouldn’t want to experience the joy of discovering 183 delightfully random and sometimes holiday-themed songs hand-picked by strangers especially for them? Sit back and tune out holiday stress in our weird worlds and perfect playlists.</p>

                <button className="modal__begin" onClick={() => { beginExperience() }}>
                    <span>Start Exploring</span>
                    <img src={'/assets/squiggle-arrow.svg'} alt="" />
                </button>
            </div>

            <Share />

            <button className="modal__close" onClick={() => { updateVisibility(false) }}>
                <svg viewBox="0 0 42 42">
                    <rect x="6" y="6" width="6" height="6"/>
                    <rect x="12" y="12" width="6" height="6"/>
                    <rect x="18" y="18" width="6" height="6"/>
                    <rect x="24" y="24" width="6" height="6"/>
                    <rect x="30" y="30" width="6" height="6"/>
                    <rect x="24" y="12" width="6" height="6"/>
                    <rect x="30" y="6" width="6" height="6"/>
                    <rect x="36" y="0" width="6" height="6"/>
                    <rect x="0" y="0" width="6" height="6"/>
                    <rect x="0" y="36" width="6" height="6"/>
                    <rect x="36" y="36" width="6" height="6"/>
                    <rect x="12" y="24" width="6" height="6"/>
                    <rect x="6" y="30" width="6" height="6"/>
                </svg>
            </button>

            <a href="https://www.ronikdesign.com" target="_blank" className="modal__made">
                <img src="/assets/made-by-ronik.svg" alt="Made by Ronik" />
            </a>
        </div>
    );
}

export default Modal;