import classNames from 'classnames'
import '../../styles/overlay.scss'

function Overlay({ currentRotation, updateRotation, currentIntroVisibility, updateIntroVisibility }) {
    const overlayClasses = classNames({
        'overlay': true,
        'is-hidden': currentIntroVisibility
    });

    const handleClick = (direction) => {
        const TURN = Math.PI / 2
        const newRotation = direction === 'ccw' ? currentRotation + TURN : currentRotation - TURN
        updateRotation(newRotation)
    }

    const openModal = () => {
        updateIntroVisibility(true)
    }

    return (
        <div className={overlayClasses}>
            <div className="overlay__nav">
                <button onClick={() => handleClick('ccw')} aria-label="counter-clockwise" />
                <button onClick={() => handleClick('cw')} aria-label="clockwise" />
            </div>

            <button
                className="overlay__info"
                onClick={() => { openModal() }}
            >
                <img src={'/assets/info.svg'} alt="information" />
                <span>What is this?</span>
            </button>
        </div>

    );
}

export default Overlay;