import React, { useEffect } from 'react'
// import { Perf } from 'r3f-perf'
import { useFrame } from '@react-three/fiber'
import { Environment } from '@react-three/drei'
import Playlists from './playlists/Playlists.js'
import MainScene from './MainScene.js'
import DebugController from './debug/Debugs.js'
import Animation from './Animation.js'
import PostProcessing from './postProcessing.js'

export default function Experience({ updatedRotation }) {
    useFrame((state, delta) => {
        const currentRotation = state.camera.rotation.y
        const rotationDelta = currentRotation - updatedRotation
        state.camera.rotation.y -= rotationDelta * delta * 2
    })

    return (
        <>
            {/* Development */}
            {/* <Perf position="top-left" />
            <DebugController /> */}
            {/* <DebugController />  */}

            {/* Animation */}
            <Animation />

            {/* Environment */}
                {/* Affects Lighting the whole scene */}
                <Environment
                    near={1}
                    far={1000}
                    resolution={256}
                    files="./models/MainScene/sky.hdr"
                    background={true}
                    blur={.5}
                />
                {/* Affects Background no lighting */}
                <Environment
                    near={1}
                    far={1000}
                    resolution={256}
                    files="./models/MainScene/StandardCubeMap.hdr"
                    background={'only'}
                    blur={0}
                />

            {/* Lights */}
            <ambientLight castShadow intensity={0.02}/>
                <spotLight castShadow color={'#2e3a31'} angle={2} position={[1, 69, -6]} intensity={2.32} />
                <spotLight castShadow color={'#a2a3d8'} angle={2.18} position={[500, 0, 100]} intensity={.4} />

            {/* Main Scene */}
            <MainScene />

            {/* Playlists */}
            <Playlists />

            {/* Post Processing */}
            <PostProcessing />
        </>
    )
}
