import Playlist from "./Playlist";

const Playlists = () => {

    return (
        <>
            {/* "West" AKA Spotify_Ronik */}
            <Playlist
                scenePos={ [{x: -270, y:70, z: 0}]}
                iFrameSource={'https://open.spotify.com/embed/playlist/42nIiOL0t4f0WiV4M6fBxn?utm_source=generator'}
                rotation={1}
            />


            {/* "East" AKA Spotify__Travels */}
            <Playlist
                scenePos={ [{x: 270, y:80, z: 10}]}
                iFrameSource={'https://open.spotify.com/embed/playlist/7acA4JoOs3AaYVnegn6Mc3?utm_source=generator'}
                rotation={3}
            />

            {/* "South" AKA Spotify__Outdoors */}
            <Playlist
                scenePos={ [{x: 0, y:80, z: 280}]}
                iFrameSource={'https://open.spotify.com/embed/playlist/5bsj4yMytvL5h3LtFCqIWE?utm_source=generator'}
                rotation={2}
            />

            
        </>
    )
}

export default Playlists
