import '../styles/mobile.scss'

const MobileFallback = () =>  {

    return (
        <div className="mobile">
            <div className="mobile__inner">
                <div className="mobile__content">
                    <div className="mobile__title">
                        <img src={'/assets/welcome-mobile.svg'} />
                    </div>

                    <p className="mobile__text">Unfortunately, this Ronik holiday experience is not designed for mobile devices. You can also check out our holiday grab bag playlist below.</p>

                    <img className="mobile__icon" src="/assets/desktop-only.svg" alt="Desktop only" />

                    <a className="mobile__listen" href="https://open.spotify.com/playlist/42nIiOL0t4f0WiV4M6fBxn?si=a31cf8f6aad9429b">
                        <img src="/assets/spotify.svg" alt="Spotify" />
                        <span>Listen now</span>
                    </a>
                </div>
            </div>

            <a href="https://www.ronikdesign.com" target="_blank" className="mobile__made">
                <img src="/assets/made-by-ronik.svg" alt="Made by Ronik" />
            </a>
        </div>
    )
}

export default MobileFallback