import React from 'react'
import { Html } from '@react-three/drei'

function Playlist({ scenePos, iFrameSource, rotation }) {
    const x = scenePos[0]['x']
    const y = scenePos[0]['y']
    const z = scenePos[0]['z']

    return (
        <mesh position={[x, y, z]}  scale={ 1 }>
            <Html
                scale={1}
                wrapperClass="playlist"
                distanceFactor={ 0 }
                position={ [ 0, 0, 0 ] }
                rotation-y={( Math.PI / 2) * rotation}
                center
                transform             
            >
                <iframe
                    src={iFrameSource}
                    style={{
                        width: '425px',
                        height: '360px',
                        position: 'absolute',
                        border: '0'
                    }}
                    allowFullScreen
                    allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
                />
            </Html>
        </mesh>
    )
}
export default Playlist