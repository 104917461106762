import React from 'react'
import { Sparkles, Stars } from '@react-three/drei'

function Animation() {
    return (
        <>
            <Sparkles count={1000} size={1} position={[0,70,0]} scale={10} speed={.4} />
            <Stars radius={500} depth={100} position={[0,90,0]} count={1000} factor={10} saturation={0} fade speed={2} />
        </>
    )
}

export default Animation
