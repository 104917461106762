import React, { useState, Suspense } from 'react'
import {Canvas} from '@react-three/fiber'
import MobileDetect from 'mobile-detect'
import Experience from './Experience.js'
import Overlay from './chrome/Overlay.js'
import Modal from './chrome/Modal.js'
import MobileFallback from "./MobileFallback";

const App = () => {
    const [cameraRotation, setCameraRotation] = useState( 0)
    const [introVisibility, setIntroVisibility] = useState(true)

    const detector = new MobileDetect(window.navigator.userAgent)

    if(!detector.phone()) {
        return (
            <>
                <Canvas
                    shadows
                    dpr={[1, 1.5]}
                    camera={ {
                        fov: 45,
                        near: 0.1,
                        far: 5000,
                        rotation: [ 0, cameraRotation, 0 ],
                        position: [ 0, 69, 0 ]
                    }}>
                    <Suspense fallback={null}>
                        <Experience
                            updatedRotation={cameraRotation}
                        />
                    </Suspense>
                </Canvas>

                <Overlay
                    currentRotation={cameraRotation}
                    updateRotation={setCameraRotation}
                    currentIntroVisibility={introVisibility}
                    updateIntroVisibility={setIntroVisibility}
                />

                <Modal
                    currentVisibility={introVisibility}
                    updateVisibility={setIntroVisibility}
                />
            </>
        )
    } else {
        return <MobileFallback />
    }

}
export default App