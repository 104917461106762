import React from 'react'
import {EffectComposer, DepthOfField, Bloom, Noise, Vignette, SSAO, HueSaturation,BrightnessContrast } from "@react-three/postprocessing";
import { BlendFunction, BlurPass, Resizer, KernelSize } from "postprocessing";

function postProcessing() {
  return (
    <>
        <fog color="#a2c6ff" attach="fog" near={1} far={5000}  />
        {/* <EffectComposer  multisampling={0} disableNormalPass={true}> */}
        <EffectComposer multisampling={0} >
            <DepthOfField
                // focusDistance={200} // where to focus
                // focalLength={60} // focal length
                focusDistance={0}
                focalLength={0.3}
                bokehScale={5} // bokeh size
                height={680}
            />
            <Bloom
                intensity={4.0} // The bloom intensity.
                blurPass={undefined} // A blur pass.
                width={Resizer.AUTO_SIZE} // render width
                height={Resizer.AUTO_SIZE} // render height
                kernelSize={KernelSize.LARGE} // blur kernel size
                luminanceThreshold={0.9} // luminance threshold. Raise this value to mask out darker elements in the scene.
                luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
            />
            <BrightnessContrast
                brightness={-0.1} // brightness. min: -1, max: 1
                contrast={.2} // contrast: min -1, max: 1
            />
            <HueSaturation
                blendFunction={BlendFunction.NORMAL} // blend mode
                hue={0} // hue in radians
                saturation={.1} // saturation in radians
            />
            {/* <SSAO
                blendFunction={BlendFunction.MULTIPLY} // blend mode
                samples={4} // amount of samples per pixel (shouldn't be a multiple of the ring count)
                rings={0} // amount of rings in the occlusion sampling pattern
                distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
                distanceFalloff={20} // distance falloff. min: 0, max: 1
                rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
                rangeFalloff={10} // occlusion range falloff. min: 0, max: 1
                luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
                radius={200} // occlusion sampling radius
                bias={0.5} // occlusion bias
                intensity={80}
                scale={20} // scale of the ambient occlusion
            /> */}
            <Noise
                opacity={.75}
                premultiply={true} // enables or disables noise premultiplication
                blendFunction={BlendFunction.ADD} // blend mode
            />
            <Vignette
                offset={0.5} // vignette offset
                darkness={0.5} // vignette darkness
                eskil={true} // Eskil's vignette technique
                blendFunction={BlendFunction.NORMAL} // blend mode
            />
        </EffectComposer>
    </>
  )
}

export default postProcessing
